* {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

.banner-main {
    margin-bottom: 50px;
}

.banner-2-sub img {
    width: 100vh;
}

.gallary-main {
    margin-top: 50px;
}

.gallary-sub {
    display: flex;
    gap: 30px;
    width: 80%;
    justify-content: center;
    margin: 0 auto;
}

.gallary-div {
    width: 25%;
}

.gallary-div a img {
    width: 100%;
    border-radius: 10px;
}

.seller-main h1 {
    margin: 20px;
}

.seller-sub {
    display: flex;
    justify-content: center;
    gap: 40px;
}

.seller-box {
    width: 20%;
    text-align: start;
}

.seller-box-img { 
    position: relative;
    width: 100%;
}

.seller-img {
    width: 100%;
}

.seller-img img {
    width: 100%;
    display: block;
    border-radius: 10px;
}

.img-text {
    position: absolute;
    top: 2%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.img-text h4 {
    padding: 5px 10px;
    background-color: #a2323f;
    font-weight: 400;
}

.img-icon {
    padding: 0 10px 0 0;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.img-data h1 {
    font-size: 16px;
    color: #000000;
    font-weight: 500;
}

.img-data span {
    color: #13294b;
    font-size: 20px;
}

.img-price h3 {
    font-size: 16px;
    color: #a2323f;
    font-weight: 500;
}

.img-price p {
    font-size: 12px;
    color: #000000;
}

.size-button {
    margin: 8px 0;
}

.size-button button {
    padding: 5px;
    border-radius: 5px;
    background-color: white;
    color: #7B7B7B;
    border: 1px solid #7B7B7B;
    margin: 0 5px;
}

.img-data h1 {
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    margin: 8px 0;
}

.img-price {
    display: flex;
    gap: 15px;
    align-items: center;
    margin: 8px 0;
    justify-content: center;
}

.cart-btn {
    width: 100%;
    background-color: #13294B;
    color: white;
    line-height: 40px;
    font-size: 18px;
    border: 0;
    border-radius: 10px;
}

.view-btn {
    background-color: white;
    border: 1px solid black;
    color: black;
    padding: 10px 30px;
    border-radius: 10px;
    margin: 30px 0;
}

.global-sub {
    display: flex;
    width: 80%;
    margin: 0 auto;
    gap: 30px;
}

.global-box img {
    width: 100%;
    border-radius: 10px;
}

.shop-btn {
    background-color: #13294B;
    border: 0;
    padding: 15px 30px;
    font-size: 15px;
    color: white;
    border-radius: 10px;
    margin: 30px;
}

.shopify-text {
    color: #15284b;
}

.shopify-text h1 {
    font-size: 19px;
    font-weight: 700;
}

.shopify-text p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
}

.shopify-text h3 {
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
}

.shopify-text a p {
    color: #15284b;
}

.shopify-sub {
    margin-bottom: 50px;
}

.terms {
    width: 80%;
    margin: 0 auto;
    font-size: 14px;
    line-height: 26px;
    font-weight: 400;
    text-align: start;
    padding: 60px;
}

.terms-main {
    background-color: #eeeeee;
}

.login-div {
    margin: 50px;
}

.login-logo {
    margin: 50px;
}

.login-text h1 {
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
    color: #000000;
    font-family: Helvetica;
    margin: 50px;
}

.login-inp {
    padding: 15px;
    width: 400px;
    margin: 15px;
    border: 0;
    border-radius: 20px;
    box-shadow: 0px 0px 12px 1px;
}

.check-div {
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;
    margin: 20px;
}

.submit-inp {
    width: 400px;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    padding: 20px;
    border: 0;
    background-color: rgb(43, 58, 84);
    color: #ffffff;
    border-radius: 30px;
}

.toggle-main a {
    text-decoration: none;
}

.toggle {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: #000000;
    font-family: Helvetica;
}

.footer-main {
    background-color: #13294b;
}

.footer-sub {
    display: flex;
}

.footer-sub h1 {
    font-size: 19px;
    font-weight: 500;
    color: #ffffff;
    font-family: Helvetica;
    line-height: 50px;
}

.footer-sub a {
    text-decoration: none;
}

.footer-sub p {
    font-size: 16px;
    line-height: 34px;
    font-weight: 400;
    color: #ffffff;
    font-family: Helvetica;
}

.footer-text {
    display: flex;
    width: 70%;
    justify-content: space-around;
    padding: 50px;
}

.footer-policy,
.footer-help,
.footer-about {
    text-align: start;
}

.footer-follow {
    width: 30%;
    text-align: start;
    padding: 50px;
}

.footer-icon a {
    text-decoration: none;
    font-size: 20px;
    line-height: 48px;
    font-weight: 400;
    color: #ffffff;
    font-family: Helvetica;
    margin: 0 20px 0 0;
}

.inp {
    margin: 30px 0;
}

.inp input {
    padding: 20px;
    border-radius: 10px 0 0 10px;
    border: 0;
    width: 300px;
}

.inp button {
    padding: 20px;
    border-radius: 0 10px 10px 0;
    border: 1px solid white;
    background-color: #13294b;
    color: white;
}

.collection-main {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 50px;
    margin: 50px 0;
}

.collection-sub {
    border: 1px solid black;
    border-radius: 30px;
    box-shadow: 0 0 20px 5px;
}

.collection-box {
    text-align: center;
    margin: 30px;
}

.collection-box-img { 
    position: relative;
    width: 100%;
}

.collection-img {
    width: 100%;
    
}

.collection-img img {
    width: 100%;
    display: block;
    border-radius: 10px;
    height: 400px;
}

.single-img-data {
    width: 30%;
    margin: 0 auto;
    margin-top: 50px;
    text-align: center;
}

.single-img-data img {
    width: 300px;
}

.single-img-data h3 {
    color: #a2323f;
}

.single-cart-btn {
    width: 200px;
    background-color: #13294B;
    color: white;
    line-height: 40px;
    font-size: 18px;
    border: 0;
    border-radius: 10px;
    margin: 50px;
}

.cart-title {
    margin: 50px;
}

.cart-box {
    display: flex;
    justify-content: space-around;
    border: 1px solid black;
    margin: 50px 0;
    padding: 30px;
    box-shadow: 0 0 20px 10px;
}

.cart-img {
    width: 20%;
}

.cart-img img {
    width: 100%;
}

.cart-text {
    width: 40%;
    text-align: start;
}

.cart-price {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.cart-price h1 {
    width: 75%;
    padding: 0;
}

.cart-price h3 {
    color: #a2323f;
}

.cart-box button {
    width: 200px;
    background-color: #13294B;
    color: white;
    line-height: 40px;
    font-size: 18px;
    border: 0;
    border-radius: 10px;
    margin: 50px;
}